import { includedLettersState, includedPositionsState, excludedLettersState, positionsState } from './state'
import { useRecoilState } from 'recoil'

const state = {
  included: null,
  excluded: null,
  positions: [],
  includedPositions: [[], [], [], [], []],
}

const setters = {
  included: null,
  excluded: null,
  positions: null,
}

const updatedPosition = (index, val) => {
  const positions = [...state.positions]
  positions[index] = val
  console.log(`includedPositions: ${JSON.stringify(positions)}`)
  return positions
}

function normalize(event) {
  return event.target.value.toLowerCase()
}

const setExcluded = (event) => setters.excluded([...new Set(normalize(event).split(''))])
const setIncludedPostion = (position, event) => {
  const positions = [...state.includedPositions]
  positions[position] = [...new Set(normalize(event).split(''))]
  setters.includedPositions(positions)
  console.log(`includedPositions: ${JSON.stringify(positions)}`)
}
const setPosition1 = (event) => setters.positions(updatedPosition(0, event.target.value.toLowerCase()))
const setPosition2 = (event) => setters.positions(updatedPosition(1, event.target.value.toLowerCase()))
const setPosition3 = (event) => setters.positions(updatedPosition(2, event.target.value.toLowerCase()))
const setPosition4 = (event) => setters.positions(updatedPosition(3, event.target.value.toLowerCase()))
const setPosition5 = (event) => setters.positions(updatedPosition(4, event.target.value.toLowerCase()))

function clearAll () {
  setters.included([])
  setters.includedPositions([[], [], [], [], []])
  setters.excluded([])
  setters.positions([])
}

function StateInputs() {
  [state.included, setters.included] = useRecoilState(includedLettersState);
  [state.includedPositions, setters.includedPositions] = useRecoilState(includedPositionsState);
  [state.excluded, setters.excluded] = useRecoilState(excludedLettersState);
  [state.positions, setters.positions] = useRecoilState(positionsState);

  return (
    <div>
      <div className="my-2 state-inputs-input-grid">
        <label htmlFor="included">Included letters</label>
        <div className="includedPositions">
          <input type="text" value={state.includedPositions[0].join('')} onChange={(e) => setIncludedPostion(0, e)} className="includedPosition" />
          <input type="text" value={state.includedPositions[1].join('')} onChange={(e) => setIncludedPostion(1, e)} className="includedPosition" />
          <input type="text" value={state.includedPositions[2].join('')} onChange={(e) => setIncludedPostion(2, e)} className="includedPosition" />
          <input type="text" value={state.includedPositions[3].join('')} onChange={(e) => setIncludedPostion(3, e)} className="includedPosition" />
          <input type="text" value={state.includedPositions[4].join('')} onChange={(e) => setIncludedPostion(4, e)} className="includedPosition" />
        </div>
        <label htmlFor="excluded">Excluded letters</label>
        <input className="excluded" type="text" value={state.excluded.join('')} onChange={setExcluded} id="excluded" />
        <label htmlFor="position1" className="state-inputs-position-label">Known letter positions</label>
        <div className="state-inputs-positions">
          <input type="text" value={state.positions[0]} onChange={setPosition1} id="position1" />
          <input type="text" value={state.positions[1]} onChange={setPosition2} />
          <input type="text" value={state.positions[2]} onChange={setPosition3} />
          <input type="text" value={state.positions[3]} onChange={setPosition4} />
          <input type="text" value={state.positions[4]} onChange={setPosition5} />
        </div>
      </div>
      <button className="bg-grey py-2 px-3 border-2 rounded my-4" onClick={clearAll}>Clear all</button>
    </div>
  )
}

export default StateInputs
