import { useRecoilValue } from 'recoil';
import { suggestedWordsState } from './state'

const itemStyles = "bg-emerald-light border-emerald-dark border-2 rounded-lg py-2 px-3 m-3 inline-block"

function Suggestions() {
  let suggestedWords = useRecoilValue(suggestedWordsState)
  let listItems = suggestedWords.map((word, i) => {
    return <li key={i} className={itemStyles}>{word}</li>
  })
  const showElipsis = () => {
    return (suggestedWords.length >= 20) ? <li key="21" className={itemStyles}>Results truncated &hellip;</li> : null
  }
  return (
    <ul className="flex flex-wrap">
      {listItems}
      {showElipsis()}
    </ul>
  )
}

export default Suggestions
