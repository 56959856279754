// import { useRecoilValue } from 'recoil'
import { AllWords } from './AllWords'

export default class WordFinder {
  constructor(known, wrongPositions, excluded, knownPositions) {
    this.known = [...new Set([...known, ...wrongPositions.flat()])]
    console.log(`known included letters: ${this.known}`)
    this.wrongPositions = wrongPositions
    this.excluded = excluded
    this.knownPositions = knownPositions
    this.maxSuggestions = 50
  }

  suggestions() {
    const suggestions = []

    AllWords.some((word) => {
      const chars = word.split('')

      const charsIncluded = [...new Set(chars.filter((c) => this.known.includes(c)))]
      // early return if word doesn't include known chars
      if (charsIncluded.length !== this.known.length) return false

      const includesCharAtWrongPosition = this.wrongPositions.find((wrongPosChars, i) => {
        return wrongPosChars.includes(chars[i])
      })

      // if (debugCount < 5) {
      //   debugger
      //   debugCount++
      // }
      // This is a bit counterintuitive. positionsIncluded represents letters
      // tried at a position that were not there, but are part of the word
      if (includesCharAtWrongPosition) return false

      const charsExcluded = chars.filter((c) => this.excluded.includes(c))
      // early return if word includes excluded chars
      if (charsExcluded.length > 0) return false

      const positionMatch = (char, i) => {
        return char === null || char === '' || char === chars[i]
      }

      if (this.knownPositions.every(positionMatch)) {
        suggestions.push(word)
      }

      return suggestions.length >= this.maxSuggestions
    })

    return suggestions
  }
}
