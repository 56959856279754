import { atom, selector } from 'recoil'
import WordFinder from './wordFinder'

export const includedLettersState = atom({
  key: 'includedLettersState',
  default: [],
})

export const includedPositionsState = atom({
  key: 'includedPositionsState',
  default: [[], [], [], [], []],
})

export const excludedLettersState = atom({
  key: 'excludedLettersState',
  default: [],
})

export const positionsState = atom({
  key: 'positionsState',
  default: ['', '', '', '', ''],
})

export const suggestedWordsState = selector({
  key: 'suggestedWordsState',
  get: ({get}) => {
    const included = get(includedLettersState)
    const includedPositions = get(includedPositionsState)
    const excluded = get(excludedLettersState)
    const positions = get(positionsState)

    const suggestions = new WordFinder(included, includedPositions, excluded, positions).suggestions()
    console.log('Word suggestions:', suggestions)
    return suggestions
  }
})
