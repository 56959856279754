import './App.css';
import StateInputs from './StateInputs'
import Suggestions from './suggestions'
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <RecoilRoot>
      <div className="pt-3 px-8 sm:w-auto lg:w-2/3 mx-auto">
        <h1 className="text-3xl font-bold">
          Wordle Suggestions
        </h1>
        <p>Ideas for words to help solve your Wordle puzzles</p>

        <StateInputs />
        <h1>Suggestions</h1>
        <Suggestions />
      </div>
    </RecoilRoot>
  );
}

export default App;
